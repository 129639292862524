import React from "react";
import { StyledButton, StyledHero } from "./style";
import heroMobileSrc from "../../../static/2020/images/impactcall/hero-mobile.jpg";
import heroDesktopSrc from "../../../static/2020/images/impactcall/hero-desktop.jpg";
import useScrollController from "../../hooks/useScrollController";

export const Hero = () => {
  const { scrollToCssSelector } = useScrollController();

  return (
    <StyledHero>
      <h1>
        Benvenuti
        <br />
        <span>al concorso</span>
        <br />
        <span>
          <span>Oscar delle api</span>
          <br />
          premi per 3000€
        </span>
        <br />
      </h1>
      <StyledButton
        big
        onClick={(e) => {
          e.preventDefault();
          scrollToCssSelector("#promotore", 160);
        }}
      >
        Scopri di più
      </StyledButton>
      <img loading="lazy" src={heroMobileSrc} className="mobileOnly" />
      <img loading="lazy" src={heroDesktopSrc} className="desktopOnly" />
    </StyledHero>
  );
};
