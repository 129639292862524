import React from "react";
import spacemanSrc from "../../../static/2020/images/impactcall/spaceman.png";
import certSrc from "../../../static/2020/images/impactcall/cert.svg";
import {
  StyledPrices,
  StyledPricesCert,
  StyledPricesItem,
  StyledPricesItems,
  StyledPricesItemsGroup,
  StyledInput,
  StyledForm,
  StyledSubmit,
} from "./style";

import { toast } from "react-toastify";

export const Prices = () => (
  <StyledPrices id="premio">
    <h2>I premi</h2>
    <p>
      Per ogni categoria un suo premio, per un valore totale di 3000€. Sponsorizzate da 3Bee e da HobbyFarm.
    </p>
    <StyledPricesItems>
      <StyledPricesItemsGroup>
        <StyledPricesItem first>
          <img src={spacemanSrc} loading="lazy" />
          <h3>
            OSCAR
            <br />
            Miglior Telaino
          </h3>
          <strong>Hive-Tech+ 500€ di attrezzature</strong>
          <p>
            <br />Un dispositivo completo per il monitoraggio 3Bee 
            e un buono da 500€ da spendere su HobbyFarm
          </p>
        </StyledPricesItem>
        <StyledPricesItem second>
          <img src={spacemanSrc} loading="lazy" />
          <h3>
            OSCAR
            <br />
            Biodiversità
          </h3>
          <strong>Hive-Tech + Tuta e Maschere</strong>
          <p>
            <br />Un dispositivo completo per il monitoraggio 3Bee 
            e una tuta da apicoltore fornita da HobbyFarm
          </p>
        </StyledPricesItem>
      </StyledPricesItemsGroup>
      <StyledPricesItem thirth>
        <img src={spacemanSrc} loading="lazy" />
        <h3>
          OSCAR
          <br />
          Essenza dell'apicoltore
        </h3>
        <strong> Hive-Tech+ Arnia da osservazione</strong>
        <p>
          <br />Un dispositivo completo per il monitoraggio 3Bee
          e un'arnia didattica con pareti in vetro fornita da HobbyFarm
        </p>
      </StyledPricesItem>
    </StyledPricesItems>
    <StyledPricesCert>
      <h2>
        <img src={certSrc} loading="lazy" />
          Il premio "La perfezione si vede dal telaino" prevede ulteriori premi.
      </h2>
      <p>
        Per il secondo classificato un'inserifilo professionale concesso da Hobbyfarm.
        per il terzo classificato uno zigrinatore professionale concesso da Hobbyfarm.
      </p>
    </StyledPricesCert>
  </StyledPrices>
);
