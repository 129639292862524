import React, { useEffect, useRef } from "react";
import {
  StyledPartners,
  StyledPartnersCarousel,
  StyledPartnersCarouselControl,
  StyledPartnersCarouselItem,
} from "./style";
import Swiper, { Navigation } from "swiper";

import partner0Src from "../../../static/2020/images/concorso/hobbyfarm.jpg";


Swiper.use([Navigation]);

export const Partners = () => {
  const sliderEl = useRef(null);
  const prevArrowEl = useRef(null);
  const nextArrowEl = useRef(null);
  const partners = [
    partner0Src,
  ];

  return (
    <StyledPartners>
      <h2>Il partner del concorso</h2>
      <div>
        {partners.map((item, index) => (
          <StyledPartnersCarouselItem key={index} className="swiper-slide">
            <img src={partner0Src} loading="lazy" />
          </StyledPartnersCarouselItem>
        ))}
      </div>
    </StyledPartners>
  );
};
