import React from "react";
import {
  StyledButton,
  StyledPromotore,
  StyledPromotoreContent,
  StyledPromotoreImage,
} from "./style";
import rocketSrc from "../../../static/2020/images/impactcall/rocket.png";

export const Promotore = () => (
  <StyledPromotore id="promotore">
    <StyledPromotoreContent>
      <h2>Il promotore</h2>
      <p>
        3Bee ® è l'azienda europea di rifermento per il monitoraggio degli alveari e la valorizzazione
        delle api. 
      </p>
      <p>
        In virtù di questa sua forte anima ha sentito la necessità di
        lanciare un concorso mai visto prima. Vogliamo premiare le api, gli alveari e
        il duro lavoro dell'apicoltore.
      </p>
      <p>
        Grazie al suo impegno e all’esperienza maturata sul campo, 3bee, è stata in
        grado di creare una rete di oltre 3000 apicoltori, monitorando e tracciando quasi 20 mila 
        alveari.{" "}
      </p>
      <StyledButton href="/">Visita il sito 3Bee</StyledButton>
    </StyledPromotoreContent>
    <StyledPromotoreImage src={rocketSrc} loading="lazy" />
  </StyledPromotore>
);
